import { HeadingL, textVariants } from '~/components/ui/Typography';
import { cn } from '~/utils/cn';

export function ServiceCard(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'flex flex-col rounded-lg p-6 shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)] md:p-10',
        props.className,
      )}
    />
  );
}
export function ServiceCardHeader(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'mb-4 flex flex-col items-center gap-6 md:items-start md:gap-8',
        props.className,
      )}
    />
  );
}

export function ServiceCardTitle(props: React.ComponentProps<'h3'>) {
  return <HeadingL {...props} className={cn('text-center md:text-left', props.className)} />;
}

export function ServiceCardContent(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'flex flex-1 flex-col items-center gap-6 text-center font-medium text-subdued-foreground md:items-start md:gap-10 md:text-left',
        textVariants({ size: 'xl' }),
        props.className,
      )}
    />
  );
}
